import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { LooksOne, LooksTwo } from "@mui/icons-material";

function CalculatorSideAchat() {
  return (
    <Box
      sx={{
        // width: "40%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        color: "black",
        backgroundColor: "yellow",
        borderRadius: 2,
        padding: 2,
      }}
    >
      <Typography variant="h4">Dotation touristique</Typography>
      <Typography paragraph="true">
        Le montant correspondant à la dotation susvisée est fixé à cent mille
        (100.000) dirhams, par personne et par année civile. Ce montant peut
        être majoré d’un supplément équivalent à 30% de l’impôt sur le revenu
        payé au Maroc au cours de l’année précédente, sans que le total n’excède
        trois cent mille (300.000) dirhams. Pour les retraités, l’octroi du
        supplément peut être effectué sur la base d’un document justifiant le
        paiement de l’impôt sur le revenu au titre de l’année de départ en
        retraite.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          paddingBottom: 2,
          border:2,
          borderRadius:2
        }}
      >
        <Typography paragraph="true">
        Vérifier votre dotation sur le site de ...
      </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            margin:2,
            color: "goldenrod",
          }}
          onClick={() => {
            // navigate("/reservation/retraitinfo")
          }}
        >
          Vérifier votre dotation
        </Button>
      </Box>
    </Box>
  );
}
export default CalculatorSideAchat;
