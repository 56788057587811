import React from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import SwipeableTextMobileStepper from "../news/SwipeableTextMobileStepper";
import Logo from "../simulator/Logo";
import NewsList from "../news/NewsList";
import FaqsList from "../faqs/FaqsList";
// import ConstructionMessage from "./ConstructionMessage";
import Steeps from "../steeps/Steeps";
import { Helmet } from "react-helmet-async";

const Actualites = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      sx={{
        pt: 0,
        pb: 4,
        width: "auto",
        backgroundColor: { xs: "black", sm: "#ffee05" },
      }}
    >
      <Helmet>
        <title>
          EXCHANGE MAROC - Change de devises à Marrakech et au Maroc
        </title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>


      <Box id="hero" sx={{ pt: 0 }}>

              <Stack
                direction="column"
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  backgroundColor: { xs: "black", sm: "#ffee05" },
                  // px: { xs: 2, sm: 4 }, // Ajout de padding horizontal
                  py: { xs: 2, sm: 4 }, // Ajout de padding vertical
                }}
              >

                    <Typography
                      component="h2"
                      variant={isSmallScreen ? "h5" : "h3"}
                      sx={{
                        color: "#212529",
                        fontWeight: "bold",
                        // display: { xs: "none", md: "flex" },
                        mb: 2, // Ajout de margin bottom
                      }}
                    >
                      Restez informé avec nos dernières actualités
                    </Typography>

                    <Typography paragraph
                      component="h4"
                      //variant={isSmallScreen ? "subtitle1" : "h5"} // Utilisation de subtitle1 pour les petits écrans
                      sx={{
                        color: "#212529",
                        mb: 2, // Ajout de margin bottom
                      }}
                    >
                      Suivez toutes les mises à jour et informations importantes
                      sur notre site. Nous vous proposons des articles, des
                      nouvelles, et des conseils pour vous tenir au courant des
                      événements récents. Consultez régulièrement cette section
                      pour ne rien manquer sur les tendances, les nouveautés et
                      les actualités marquantes.
                    </Typography>
                    <Typography paragraph sx={{ color: "#212529", mb: 2 }}>
                      Prêt à commencer ? Réservez dès aujourd'hui votre
                      opération de change d'argent avec EXCHANGE.COM et
                      découvrez la différence que nous pouvons faire pour vous !
                    </Typography>

                <SwipeableTextMobileStepper />
                </Stack>
              </Box>

    </Container>
  );
};

export default Actualites;
