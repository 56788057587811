import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Steeps from '../steeps/Steeps';
import MediaControlCard from './MediaControlCard';

export default function SteepReservation() {
  const navigate = useNavigate();

  return (
    <Box sx={{ 
        background: "black",
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center' 
      }}>
      <Typography variant="h4" sx={{ color: 'yellow', padding: 4 }}>
        Changer malin, voyager serein !
      </Typography>
      
      <MediaControlCard />

      <Typography 
        variant="h4" 
        sx={{
          color: 'white', 
          pt: { xs: 4, md: 8 }, // Responsive padding top
          fontSize: { xs: '1.2rem', md: '2rem' } // Responsive font size
        }}
      >
        Réserver gratuitement en ligne en trois étapes
      </Typography>

      <Typography 
        variant="body1" 
        sx={{ 
          color: 'white', 
          pt: 4, 
          px: { xs: 2, md: 16 }, // Responsive padding for text
          fontSize: { xs: '0.9rem', md: '1rem' }, // Responsive font size
          textAlign: 'justify' // Justify text for better readability
        }}
      >
        La réservation de vos devises n’a jamais été aussi simple ! Suivez ces trois étapes faciles : indiquez le montant souhaité, choisissez votre bureau de récupération, et entrez vos coordonnées. En un rien de temps, vous aurez sécurisé votre réservation, vous permettant ainsi de voyager sereinement sans stress ni files d'attente.
      </Typography>

      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Steeps />
      </Container>
    </Box>
  );
}
