import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import Calculator from "../calculator/Calculator";
import CalculatorSide from "./CalculatorSide";
import TypeOperation from "../calculator/TypeOperation";
import CalculatorVente from "../calculator/CalculatorVente";
import CalculatorSideVente from "./CalculatorSideVente";

const SimulatorVente = () => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent={"space-around"}
    >
      <CalculatorVente />
      <CalculatorSideVente />
    </Stack>
  );
};

export default SimulatorVente;
