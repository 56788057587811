import React from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetReservationQuery,
  useUpdateReservationMutation,
} from "../../../app/services/reservationApi";
import { useGetAllDevisesQuery } from "../../../app/services/deviseApi"; // Import the hook

import Loader from "../../loader/Loader";
import { useParams } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function DetailsReservation() {
  const { id } = useParams();
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetReservationQuery(id);
  const [updateReservation, { data: data1, isLoading: isLoading1, isError: isError1 }] = useUpdateReservationMutation();

  // Fetch all devises
  const { data: devises, isLoading: isLoadingDevises, isError: isErrorDevises } = useGetAllDevisesQuery();

  const handleReservationStatus = () => {
    updateReservation(data.Reservation.id);
  };

  // Find the current devise based on reservation
  const currentDevise = devises?.find(devise => devise.nom === data?.Reservation.devise);

  return (
    <Container maxWidth="lg">
      <Stack direction="column" spacing={2} sx={{ marginTop: 2 }}>
        <Typography variant="h2" align="center" color="black">
          Gestion des réservations
        </Typography>

        {error ? (
          <>Oh no, there was an error</>
        ) : isLoading || isLoadingDevises ? (
          <Loader />
        ) : data && devises ? (
          <Box sx={{ backgroundColor: "goldenrod", padding: 2, borderRadius: 4 }}>
            <Typography variant="h5" color="black">
              Informations du client
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="code-reservation-label">Code réservation</InputLabel>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  size="small"
                  value={data.Reservation.id}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="nom-famille-label">Nom de famille</InputLabel>
                <TextField
                  fullWidth
                  id="nomFamille"
                  name="nomFamille"
                  size="small"
                  value={data.Reservation.nomFamille}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="prenom-label">Prénom</InputLabel>
                <TextField
                  fullWidth
                  id="prenom"
                  name="prenom"
                  size="small"
                  value={data.Reservation.prenom}
                  disabled
                />
              </Grid>
            </Grid>

            <Typography variant="h5" color="black" sx={{ marginTop: 2 }}>
              Informations de la réservation
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="etat-label">État</InputLabel>
                <TextField
                  fullWidth
                  id="etat"
                  name="etat"
                  size="small"
                  value={data.Reservation.etat}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="devise-label">Devise</InputLabel>
                <TextField
                  fullWidth
                  id="devise"
                  name="devise"
                  size="small"
                  value={data.Reservation.devise}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="type-operation-label">Type d'opérations</InputLabel>
                <TextField
                  fullWidth
                  id="typeOperation"
                  name="typeOperation"
                  size="small"
                  value={data.Reservation.typeOperation === "Achat" ? "Vente" : "Achat"}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="date-reservation-label">Date de réservation</InputLabel>
                <TextField
                  fullWidth
                  id="dateReservation"
                  name="dateReservation"
                  size="small"
                  value={data.Reservation.created_at}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="taux-label">Taux</InputLabel>
                <TextField
                  fullWidth
                  id="taux"
                  name="taux"
                  size="small"
                  value={data.Reservation.taux}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="montant-payer-label">Bureau de récupération</InputLabel>
                <TextField
                  fullWidth
                  id="bureau"
                  name="bureau"
                  size="small"
                  value={data.bureau.nom}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="montant-payer-label">Montant à payer par le client</InputLabel>
                <TextField
                  fullWidth
                  id="montantPayer"
                  name="montantPayer"
                  size="small"
                  value={data.Reservation.montantToExchange}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel id="montant-recevoir-label">Montant à donner au client</InputLabel>
                <TextField
                  fullWidth
                  id="montantRecevoir"
                  name="montantRecevoir"
                  size="small"
                  value={data.Reservation.MontantToReceive}
                  disabled
                />
              </Grid>
            </Grid>
            <Button variant="contained" onClick={handleReservationStatus} sx={{ marginTop: 2 }}>
              Valider l'opération
            </Button>

            {data1?.message && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ marginTop: 2 }}>
                {data1?.message}
              </Alert>
            )}
          </Box>
        ) : null}
      </Stack>
    </Container>
  );
}

export default DetailsReservation;
