import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";

import { Outlet, useNavigate } from "react-router-dom";

const MenuAdmin = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      spacing={4}
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{
        backgroundColor: "yellow",

        padding: 4,
        // display:'flex',
        // alignItems:'center',
      }}
    >
      <Typography variant="h3" align="center">
        ESPACE DE GESTION
      </Typography>
      <hr />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={4}
        justifyContent={"center"}
        alignItems={"center"}
        // sx={{
        //   padding: { xs: 2, sm: 4 },
        // }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
            width: 160,
            height: 80,
          }}
          onClick={() => {
            navigate("/admin/gestionreservation");
          }}
        >
          RESERVATION
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
            width: 160,
            height: 80,
          }}
          onClick={() => {
            navigate("/admin/deviselistedit");
          }}
        >
          DEVISE
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
            width: 160,
            height: 80,
          }}
          onClick={() => {
            navigate("/admin/faqsadmin");
          }}
        >
          FAQs
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
            width: 160,
            height: 80,
          }}
          onClick={() => {
            navigate("/admin/newsadmin");
          }}
        >
          NEWS
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
            width: 160,
            height: 80,
          }}
          onClick={() => {
            navigate("/admin/contact");
          }}
        >
          MESSAGES
        </Button>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default MenuAdmin;
