import React from 'react';
import { Box, Link, Typography, Stack } from '@mui/material';


const PartnerLinks = () => {
    const partners = [
        { name: 'Office des Changes', url: 'https://www.oc.gov.ma/fr', image: 'officedeschanges.png' },
        { name: 'BANK AL-MAGHRIB', url: 'https://www.bkam.ma', image: 'bankalmaghreb.png' },
        { name: 'Africa Exchange', url: 'https://africaexchange.ma', image: 'GIE.png' },
    ];

    return (
        <Box 
            component="footer"
            sx={{
                backgroundColor: 'black', // Couleur de fond noire
                color: 'white', // Texte en blanc
                padding: '20px 0',
                textAlign: 'center',
                marginTop: 'auto',
             //   borderRadius: { xs: '0', sm: '160px 0 0 0' }, // Arrondi du footer
                boxShadow: 3, // Ajouter une ombre pour plus de profondeur
            }}
        >
            <Typography variant="h4" sx={{ marginBottom: 4, color: 'yellow' }}>
                Nos Partenaires
            </Typography>
            {/* <Typography variant="body1" sx={{ marginBottom: 4 }}>
                Nous collaborons avec des partenaires de confiance pour vous offrir les meilleurs services d'échange. Découvrez nos partenaires ci-dessous.
            </Typography> */}
            <Stack 
                direction={{ xs: 'column', sm: 'row' }} // Aligne verticalement sur petits écrans, horizontalement sur grands écrans
                justifyContent="center" 
                alignItems={{ xs: 'center', sm: 'end' }} // Centre les éléments
                spacing={4} // Espacement entre les partenaires
                sx={{ flexWrap: 'wrap' }} // Permet aux éléments de passer à la ligne
            >
                {partners.map((partner, index) => (
                    <Link
                        key={index}
                        href={partner.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            display: 'flex', 
                            flexDirection: 'column', // Images au-dessus des noms
                            alignItems: 'center', // Centre les éléments horizontalement
                            color: 'yellow', // Texte en jaune
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                color: 'white', // Texte blanc au survol
                            }
                        }}
                    >
                        <img 
                            src={`${process.env.REACT_APP_URL_IMG}/images/partenaires/${partner.image}`}
                            alt={partner.name}
                            style={{
                                width: '80px', // Taille uniforme des logos
                                height: 'auto', // Conserver les proportions de l'image
                                marginBottom: '10px', // Espace entre l'image et le texte
                            }}
                        />
                        <Typography>{partner.name}</Typography>
                    </Link>
                ))}
            </Stack>
        </Box>
    );
};

export default PartnerLinks;
