import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import Calculator from "../calculator/Calculator";
import CalculatorSide from "./CalculatorSide";
import TypeOperation from "../calculator/TypeOperation";
import CalculatorAchat from "../calculator/CalculatorAchat";
import CalculatorSideAchat from "./CalculatorSideAchat";

const SimulatorAchat = () => {
  return (


      <Stack direction={{xs:'column',sm:'row'}} justifyContent={'space-around'}>
        <CalculatorAchat/>
        <CalculatorSideAchat/>
    </Stack>
  );
};

export default SimulatorAchat;
