import { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import "dayjs/locale/de";

import React, { useState } from "react";
import phone from "../../data/phone.json";
import InputSelect from "./InputSelect";
import InputSelectPays from "./InputSelectPays";
import { useNavigate } from "react-router-dom";

import { useAddReservationMutation } from "../../app/services/reservationApi";
import { useSelector } from "react-redux";
import ChangeInfo from "./ChangeInfo";
import RecapRetraitInfo from "./recapRetraitInfo";
import Conditions from "./Conditions";
import CustomTabPanel from "./ReservationClient";
import Loader from "../loader/Loader";

function Coordonnes() {
  const navigate = useNavigate();
  const state = useSelector((state) => state.change.value);
  const stateRetrait = useSelector((state) => state.reservation.value);

  const [reservation, setReservation] = useState({
    user_id: 1,
    nomFamille: "",
    nomFamille2: "",
    prenom: "",
    email: "",
    indicatif: "+212(MA)",
    dateNaissance: dayjs("2022-04-20"),
    paysDocIdentite: "Morocco",
    montantToExchange: state.montantToExchane,
    MontantToReceive: state.MontantToReceive,
    typeOperation: state.typeOperation,
    devise: state.deviseToExchange.name,
    devise_id: state.deviseToExchange.id,
    taux:
      state.typeOperation === "achat"
        ? state.deviseToExchange.prixAchat
        : state.deviseToExchange.prixVente,
    bureauRetrait: stateRetrait.bureauRetrait,
    dateRetrait: stateRetrait.dateRetrait,
    heureRetrait: stateRetrait.heureRetrait,
    telephone: "",
  });

  const [addReservation, { data: reservation1, isLoading, isError, error }] =
    useAddReservationMutation();

  const [giveSelectValue, setGiveSelectValue] = useState("");
  const members = phone?.map((phone) => (
    <MenuItem value={phone.name} key={phone.code}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {phone.code} {phone.dial_code}
      </Box>
    </MenuItem>
  ));

  const [isChecked, setIsChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  // Confirmation email
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState(""); // État pour les erreurs de téléphone

  const [loading, setLoading] = useState(false);
  // Effect to update button state when checkbox state changes
  useEffect(() => {
    setIsButtonEnabled(isChecked);
  }, [isChecked]);
  useEffect(() => {
    if (reservation.montantToExchange === 0) {
      alert(
        "Votre session de réservation a expiré. Veuillez recommencer le processus pour finaliser votre réservation. Merci de votre compréhension !"
      );
      navigate("/reservation");
    }
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Function to validate phone number
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[1-9]\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleForm = (e) => {
    e.preventDefault();
    // Validate phone number
    if (!validatePhoneNumber(reservation.telephone)) {
      setErrorPhone(
        "Veuillez saisir un numéro de téléphone valide. Exemple: 6 XX XX XX XX"
      );
      return;
    } else {
      setErrorPhone("");
    }

    if (email !== confirmEmail) {
      setErrorEmail("Les adresses email ne correspondent pas.");
      return;
    }

    setLoading(true);
    addReservation(reservation)
      .then((data) => {
        if (data && data.data && data.data.id) {
          navigate(`/reservation/confirmation/${data.data.id}`);
        } else {
          console.error("Erreur : les données de réservation sont manquantes");
          alert(
            "Erreur : les données de réservation sont manquantes",
            data.data.id
          );
        }
      })
      .catch((error) => {
        alert("Erreur : les données de réservation sont manquantes");
        console.error("Erreur lors de l'ajout de la réservation :", error);
        setMessage("Erreur lors de l'inscription. Veuillez réessayer.");
        setErrorEmail("");

        if (error.originalStatus === 500) {
          console.error("Erreur serveur. Veuillez réessayer plus tard.");
          alert("Erreur serveur. Veuillez réessayer plus tard.");
        } else if (error.status === "PARSING_ERROR") {
          console.error(
            "Erreur de parsing. La réponse n'est pas du JSON valide."
          );
          alert("Erreur de parsing. La réponse n'est pas du JSON valide.");
        } else {
          console.error("Une erreur inattendue s'est produite.");
          alert("Une erreur inattendue s'est produite.", error.message);
          console.log(error);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call
      });
  };

  // Handle indicatif change
  const handleIndicatifChange = (newIndicatif) => {
    setReservation((state) => ({
      ...state,
      indicatif: newIndicatif,
    }));
  };

  return (
    <Stack direction={{ xs: "column-reverse", sm: "row" }} spacing={2}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          border: "solid 1px goldenrod",
        }}
      >
        <Box
          sx={{
            backgroundColor: "black",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography variant="h5" color="goldenrod">
            J'enregistre mes coordonnées
          </Typography>
        </Box>
        <Box
          sx={{
            padding: 2,
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <form onSubmit={handleForm}>
              <Stack direction={"column"} spacing={2} align="center">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Stack direction={"column"} spacing={2} align="center">
                    <InputLabel id="nom">Nom de famille</InputLabel>
                    <TextField
                      required
                      label="Nom de famille"
                      id="nomFamille"
                      name="nomFamille"
                      size="small"
                      value={reservation.nomFamille}
                      onChange={(event) =>
                        setReservation((state) => ({
                          ...state,
                          nomFamille: event.target.value,
                        }))
                      }
                    />
                  </Stack>

                  <Stack direction={"column"} spacing={2} align="left">
                    <InputLabel id="nomFamille2">
                      Deuxième nom de famille
                    </InputLabel>
                    <TextField
                      label="Deuxième nom de famille"
                      id="nomFamille2"
                      size="small"
                      value={reservation.nomFamille2}
                      onChange={(event) =>
                        setReservation((state) => ({
                          ...state,
                          nomFamille2: event.target.value,
                        }))
                      }
                    />
                  </Stack>
                </Stack>

                <Stack direction={"column"} spacing={2} align="left">
                  <InputLabel id="Prenom">Prénom</InputLabel>
                  <TextField
                    label="Prénom"
                    id="prenom"
                    size="small"
                    value={reservation.prenom}
                    onChange={(event) =>
                      setReservation((state) => ({
                        ...state,
                        prenom: event.target.value,
                      }))
                    }
                  />
                </Stack>

                <Divider />
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={4}
                  justifyContent="space-between"
                >
                  <Stack direction={"column"} spacing={2} align="left">
                    <InputLabel id="email">Email</InputLabel>
                    <TextField
                      required
                      label="Email"
                      id="email"
                      size="small"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Stack>

                  <Stack direction={"column"} spacing={2} align="left">
                    <InputLabel id="emailConfirmation">
                      Confirmer votre Email
                    </InputLabel>
                    <TextField
                      required
                      label="Confirmer Email"
                      id="email2"
                      name="email2"
                      size="small"
                      value={confirmEmail}
                      onChange={(event) => {
                        setConfirmEmail(event.target.value);
                        setReservation((state) => ({
                          ...state,
                          email: event.target.value,
                        }));
                      }}
                    />
                  </Stack>
                </Stack>
                {errorEmail && <Alert severity="warning">{errorEmail}</Alert>}
                {message && <p>{message}</p>}
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={4}
                  justifyContent="space-between"
                >
                  <Stack direction={"column"} spacing={2} align="left">
                    <InputLabel id="telephone">Numéro de téléphone</InputLabel>
                    <Stack direction={"row"} spacing={1} height={"30px"}>
                      <InputSelect onChange={handleIndicatifChange} />
                      <TextField
                        required
                        label="Téléphone"
                        id="telephone"
                        name="telephone"
                        size="small"
                        value={reservation.telephone}
                        onChange={(event) =>
                          setReservation((state) => ({
                            ...state,
                            telephone: event.target.value,
                          }))
                        }
                      />
                    </Stack>
                    {errorPhone && (
                      <Alert severity="warning">{errorPhone}</Alert>
                    )}
                  </Stack>

                  <Stack direction={"column"} spacing={2} align="left">
                    <InputLabel id="adresse">Adresse</InputLabel>
                    <TextField
                      label="Adresse"
                      size="small"
                      id="adresse"
                      name="adresse"
                      multiline
                      maxRows={4}
                      onChange={(event) =>
                        setReservation((state) => ({
                          ...state,
                          adresse: event.target.value,
                        }))
                      }
                    />
                  </Stack>
                </Stack>
                {state.typeOperation !== "achat" && (
                  <Typography variant="h5">
                    Merci de présenter vos documents d'identité au moment du
                    retrait
                  </Typography>
                )}

                <Conditions />

                <Stack direction={"row"} spacing={2} justifyContent={"left"}>
                  <Checkbox
                    label=""
                    color="primary"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <Typography
                    paragraph="true"
                    sx={{ padding: "16px 0", m: 0 }}
                    color="secondary"
                  >
                    J'ai lu et j'accepte
                    <Link
                      color="inherit"
                      href="/conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      La politique de confidentialité et les conditions
                      générales d'utilisation du site
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                type="submit"
                disabled={!isButtonEnabled}
              >
                Passer ma reservation
              </Button>
            </form>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

export default Coordonnes;
