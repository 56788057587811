import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetDeviseQuery, useUpdateDeviseMutation } from "../../app/services/deviseApi";
import Loader from "../loader/Loader";
import { useParams, useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function DeviseEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState('');
  const [pays, setPays] = useState('');
  const [prixAchat, setPrixAchat] = useState('');
  const [prixVente, setPrixVente] = useState('');
  const [updateDevise] = useUpdateDeviseMutation();
  const { data, isLoading, isSuccess, isError, error } = useGetDeviseQuery(id);

  useEffect(() => {
    if (data) {
      setName(data.devise.name);
      setPays(data.devise.pays);
      setPrixAchat(data.devise.prixAchat);
      setPrixVente(data.devise.prixVente);
    }
  }, [data]);
  const handleForm = (e) => {
    e.preventDefault();
    console.log('Test devise modification')
    updateDevise({
      id,
      devise: {
        name,
        pays,
        prixAchat: parseFloat(prixAchat),
        prixVente: parseFloat(prixVente),
      }
    }).then((response) => {
      if (response.error) {
        console.error('Failed to update devise:', response.error);
      } else {
        navigate("/admin/deviselistedit");
      }
    });
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          backgroundColor: "goldenrod",
          padding: { xs: 2, sm: 2, md: 8 },
          borderRadius: 4,
        }}
      >
        <Typography variant="h4" sx={{ pt: 2, pb: 4, color: "black" }}>
          Modifier Devise
        </Typography>
        <form onSubmit={handleForm} key={id}>
          {error ? (
            <>Une erreur est survenue</>
          ) : isLoading ? (
            <Loader />
          ) : data ? (
            <>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Stack direction="column" spacing={2}>
                  <TextField
                    helperText="Entrer la devise"
                    id="devise"
                    label="Devise"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <TextField
                    helperText="Entrer le pays"
                    id="pays"
                    label="pays"
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                  />
                  <TextField
                    helperText="Entrer le prix d'achat"
                    id="prixAchat"
                    label="prix d'achat"
                    type="number"
                    value={prixAchat}
                    onChange={(e) => setPrixAchat(e.target.value)}
                  />

                  <TextField
                    helperText="Entrer le prix de vente"
                    id="prixVente"
                    label="prix de vente"
                    type="number"
                    value={prixVente}
                    onChange={(e) => setPrixVente(e.target.value)}
                  />
                </Stack>
                <Stack direction={"column"} spacing={2}>
                  <img src="images/maroc.jpg" alt="" width="240px" />
                  <Box sx={{ width: 240 }}>
                    <img
                      src={`https://exchange-maroc.com/laravel/storage/Devise/image/${data.devise.image}`}
                      alt={data.devise.pays}
                      width="40"
                    />
                  </Box>
                </Stack>
              </Stack>
              <Button variant="contained" type="submit">
                Enregistrer
              </Button>
            </>
          ) : null}
        </form>
      </Box>
    </Container>
  );
}

export default DeviseEdit;
