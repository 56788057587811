import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const steps = [
  "Sélectionner les montants de réservation",
  "Choisir le bureau et l'horaire de retrait",
  "Saisir vos coordonnées",
  "Terminé",
];

export default function Steeper() {
  const state=useSelector((state)=>state.reservation.value);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stepper activeStep={state.steep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
                <Typography variant="h6" >
                {label}
                </Typography>
                </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
