import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Stack } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useGetAllBureausQuery } from "../../app/services/bureauApi";
import Loader from '../loader/Loader';

export default function BureauList() {
    
  
    // const {id}=useParams();

    // const id=1;
    const {
        data: bureaux,
        isLoading,
        isSuccess,
        isError,
        error,
      } = useGetAllBureausQuery();
      

      if (isLoading) {
        return <Loader/>
     }
     console.log(bureaux)
    
  return (
    
    <Stack direction={{xs:'column', sm:'row'}} spacing={2} useFlexGap flexWrap="wrap" justifyContent={'center'}>
     
     {bureaux?.map((bureau) => (

        
        <Card sx={{ maxWidth: 345, 
            padding:4,
            marginTop:4
            }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={`https://exchange-maroc.com//images/bureaux/${bureau.image}`}
                  // image="/images/bureaux/b1.jpg"
                  alt="AFRICA EXCHANGE"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                     {bureau.nom}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {bureau.adresse}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {bureau.Bureau?.description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {bureau?.telephone}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {bureau?.email}
                  </Typography>
        
                </CardContent>
              </CardActionArea>
            </Card>
    ))}
    </Stack>
  );
}