import React from 'react'

import Simulator from '../simulator/Simulator'
import { Box, Container, Stack, Typography } from '@mui/material'
import Conditions from '../reservation/Conditions'
import DeviseList from '../devise/DeviseList'
import ServiceCard from './ServiceCard';
import { Helmet } from 'react-helmet-async';

function Services() {
  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      pt: { xs: 4, sm: 8 },
      pb: { xs: 4, sm: 8 },
    }}
    >
            <Helmet>
        <title>Nos services - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography variant='h3'
      align='center'
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color:'black',
        pb: { xs: 4, sm: 8 },
      }}
      >
        Profitez de nous services et promotions
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2,fontSize: { xs: 32, sm: 56 }, md: 4 }} useFlexGap flexWrap="wrap">
        <ServiceCard/>
        {/* <ServiceCard/>
        <ServiceCard/>
        <ServiceCard/>
        <ServiceCard/> */}
      </Stack>     
    </Container>
  )
}

export default Services
