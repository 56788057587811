import { Box, Button, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Bureau from "./Bureau";
import ChangeInfo from "./ChangeInfo";
import { addBureau } from "../../app/services/reservationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllBureausQuery } from "../../app/services/bureauApi";
import Loader from "../loader/Loader";
import dayjs from "dayjs";
import { format } from 'date-fns';

function RetraitInfo() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const dispatch = useDispatch();
  const typeOperation = useSelector((state) => state.change.value.typeOperation);
  const dateActuelle = dayjs();
  const dateActuelleFormatee = format(dateActuelle.toDate(), 'dd/MM/yyyy');

  const [dateRetrait, setDateRetrait] = useState(dayjs());
  const [heureRetrait, setHeureRetrait] = useState();
  const [bureauId, setBureauId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { data: bureaus, isLoading } = useGetAllBureausQuery();

  const members = bureaus?.map((bureau) => (
    <MenuItem key={bureau.id} value={bureau.id}>
      {bureau.nom}
    </MenuItem>
  ));

  if (isLoading) {
    return <Loader />;
  }

  const handleChangeBureau = (event) => {
    setBureauId(event.target.value);
    setErrorMessage("");
  };

  const handleChangeHeure = (event) => {
    setHeureRetrait(event.target.value);
  };

  const handleSuivantClick = () => {
    if (!bureauId || !dateRetrait) {
      setErrorMessage("Veuillez sélectionner un bureau et une date de retrait.");
      return;
    }

    dispatch(
      addBureau({
        bureauRetrait: bureauId,
        dateRetrait,
        heureRetrait,
        steep: 3,
      })
    );
    navigate("/reservation/reservationclient");
  };

  const handlePrecedentClick = () => {
    if (!bureauId || !dateRetrait) {
      setErrorMessage("Veuillez sélectionner un bureau et une date de retrait.");
      return;
    }

    dispatch(
      addBureau({
        bureauRetrait: bureauId,
        dateRetrait,
        heureRetrait,
        steep: 1,
      })
    );
    navigate(typeOperation === 'vente' ? "/reservation/simulatorachat" : "/reservation/simulatorvente");
  };

  return (
    <Stack
      id="bureau"
      direction={{ xs: 'column', sm: 'row' }} 
      spacing={2} 
      justifyContent="space-between"
     // sx={{ padding: 2 }} // Added padding for the entire stack
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 2,
          borderRadius: 2,
          border: "solid 1px goldenrod",
          flex: 1, // Allows the Box to grow and fill available space
         // maxWidth: '600px', // Set max width for larger screens
        }}
      >
        <Box
          sx={{
            backgroundColor: "black",
            width: "100%",
            borderRadius: 2,
            padding: "16px",
            mb: 2, // margin-bottom for spacing below header
          }}
        >
          <Typography variant="h6" color="goldenrod" textAlign="center">
            Sélectionner le bureau de change et la date de retrait de votre argent
          </Typography>
        </Box>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2}
        >
          <Box
            sx={{
              padding: 2,
              flex: 1, // Allows the Box to grow and fill available space
            }}
          >
            <form>
              <InputLabel id="bureau-label">Bureau de retrait</InputLabel>
              <Select
                labelId="bureau-label"
                id="bureau-select"
                label="Bureau"
                onChange={handleChangeBureau}
                sx={{ minWidth: '100%' }} // Full width for responsiveness
              >
                {members}
              </Select>

              <InputLabel id="date-label" sx={{ mt: 2 }}>Date de retrait</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ maxWidth: '100%', mt: 1 }}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date de retrait"
                      value={dateRetrait}
                      onChange={(date) => setDateRetrait(dayjs(date))}
                      minDate={dateActuelle}
                      sx={{ width: '100%' }} // Full width for responsiveness
                    />
                  </DemoContainer>
                </Box>
              </LocalizationProvider>

              <InputLabel id="heure-label" sx={{ mt: 2 }}>Heure de retrait</InputLabel>
              <Select
                labelId="heure-label"
                id="heure-select"
                onChange={handleChangeHeure}
                sx={{ minWidth: '100%' }} // Full width for responsiveness
              >
                <MenuItem value={"08:00 - 10:00"}>08:00 - 10:00</MenuItem>
                <MenuItem value={"10:00 - 12:00"}>10:00 - 12:00</MenuItem>
                <MenuItem value={"12:00 - 14:00"}>12:00 - 14:00</MenuItem>
                <MenuItem value={"14:00 - 16:00"}>14:00 - 16:00</MenuItem>
                <MenuItem value={"16:00 - 18:00"}>16:00 - 18:00</MenuItem>
                <MenuItem value={"18:00 - 20:00"}>18:00 - 20:00</MenuItem>
                <MenuItem value={"22:00 - 23:59"}>22:00 - 23:59</MenuItem>
              </Select>
            </form>

            {errorMessage && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}

            <Box sx={{ mt: 3 }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  paddingBottom: 2,
                  justifyContent: { xs: 'center', sm: 'flex-start' }, // Center on small screens
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "goldenrod",
                    color: "black",
                  }}
                  onClick={handlePrecedentClick}
                >
                  Précédent
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "goldenrod",
                    color: "black",
                  }}
                  onClick={handleSuivantClick}
                >
                  Suivant
                </Button>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>{bureauId && <Bureau id={bureauId} />}</Box>
        </Stack>
      </Box>

      <ChangeInfo />
    </Stack>
  );
}

export default RetraitInfo;
