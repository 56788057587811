import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGetAllContactsQuery } from "../../app/services/contactApi";
import Loader from "../loader/Loader";

const Contact = () => {
  const { data: messages, error, isLoading } = useGetAllContactsQuery();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  console.log("Messages:", messages); // Vérifiez les données récupérées dans la console
  console.log("Error:", error); // Vérifiez s'il y a des erreurs dans la console
  console.log("Loading:", isLoading); // Vérifiez le statut de chargement dans la console

  if (isLoading) return <Loader />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "white",
        padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des Messages</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les messages reçus depuis le formulaire de contact.
      </Typography>
      <Grid container spacing={isLargeScreen ? 3 : 1}>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="messages table">
              <TableBody>
                {messages.map((message) => (
                  <TableRow key={message.id}>
                    <TableCell>
                      <Typography variant="body1">
                        <strong>{message.name}: {message.email}</strong>
                      </Typography>
                      <Typography variant="body2">{message.message}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
