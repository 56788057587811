import logo from "./logo.svg";
import "./App.css";
import ResponsiveAppBar from "./components/header/ResponsiveAppBar";
import Home from "./components/home/Home";
import DeviseList from "./components/devise/DeviseList";
import { Link, Route, Routes } from "react-router-dom";
import Gestion from "./components/gestion/Gestion";
import Reservation from "./components/reservation/Reservation";
import Services from "./components/services/Services";
import Aboutus from "./components/aboutus/Aboutus";
import Simulator from "./components/simulator/Simulator";
import Coordonnes from "./components/reservation/Coordonnes";
import RetraitInfo from "./components/reservation/RetraitInfo";
import ConfirmReservation from "./components/reservation/ConfirmReservation";
import TypeOperation from "./components/calculator/TypeOperation";
import CalculatorAchat from "./components/calculator/CalculatorAchat";
import CalculatorVente from "./components/calculator/CalculatorVente";
import SimulatorAchat from "./components/simulator/SimulatorAchat";
import SimulatorVente from "./components/simulator/SimulatorVente";
import Devise from "./components/devise/Devise";
import DeviseListEdit from "./components/devise/DeviseListEdit";
import DeviseEdit from "./components/devise/DeviseEdit";
import DeviseAdd from "./components/devise/DeviseAdd";
import Login from "./components/admin/authentification/Login";
import MenuAdmin from "./components/admin/MenuAdmin";
import GestionReservation from "./components/reservation/gestion/GestionReservation";
import DetailsReservation from "./components/reservation/gestion/DetailsReservation";
import { Box, Typography } from "@mui/material";
import Contact from "./components/contact/Contact";
import ReservationClient from "./components/reservation/ReservationClient";
import Register from "./components/admin/authentification/Register";
import Profile from "./components/admin/authentification/Profile";
import ProtectedLayout from "./components/admin/authentification/ProtectedLayout";
import FaqsAdmin from "./components/admin/FaqsAdmin";
import NewsAdmin from "./components/admin/NewsAdmin";
import CreateNewsForm from "./components/admin/CreateNewsForm";
import Dashboard from "./components/admin/Dashboard";
import FaqsList from "./components/faqs/FaqsList";
import ContactList from "./components/admin/ContactList";
import News from "./components/news/News";
import ConditionsGenerales from "./components/reservation/ConditionsGenerales";
import Copyright from "./components/Copyright";

import PolitiqueConfidentialite from "./components/reservation/PolitiqueConfidentialite";
import PolitiqueCookies from "./components/reservation/PolitiqueCookies";
import NotificationsBar from "./components/header/NotificationsBar";
import Footer from "./components/footer/Footer";
import SteepReservation from "./components/home/SteepReservation";
import Actualites from "./components/news/Actualites";
import CookieConsent from "react-cookie-consent";


function App() {
  return (
    <Box>
      <header className="App-header">
        <ResponsiveAppBar />
        {/* <NotificationsBar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login connection="login" />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/pro" element={<ProtectedLayout />} /> */}
          <Route path="/admin" element={<MenuAdmin />}>
            <Route index element={<GestionReservation />} />
            <Route
              path="/admin/gestionreservation"
              element={<GestionReservation />}
            />
            <Route path="/admin/deviselistedit" element={<DeviseListEdit />} />
            <Route
              path="/admin/detailsreservation/:id"
              element={<DetailsReservation />}
            />
            <Route path="/admin/faqsadmin" element={<FaqsAdmin />} />
            <Route path="/admin/newsadmin" element={<NewsAdmin />} />
            <Route path="/admin/createnewsadmin" element={<CreateNewsForm />} />

            <Route path="/admin/deviseedit/:id" element={<DeviseEdit />} />
            <Route path="/admin/deviseadd" element={<DeviseAdd />} />
            <Route path="/admin/contact" element={<ContactList />} />
          </Route>
          <Route path="/reservation" element={<Reservation />}>
            <Route index element={<TypeOperation />} />
            <Route
              path="/reservation/typeoperation"
              element={<TypeOperation />}
            />
            <Route
              path="/reservation/simulatorachat"
              element={<SimulatorAchat />}
            />
            <Route
              path="/reservation/simulatorvente"
              element={<SimulatorVente />}
            />
            <Route
              path="/reservation/reservationclient"
              element={<ReservationClient />}
            />
            <Route path="/reservation/retraitinfo" element={<RetraitInfo />} />
            <Route
              path="/reservation/confirmation"
              element={<ConfirmReservation />}
            />
            <Route
              path="/reservation/confirmation/:id"
              element={<ConfirmReservation />}
            />
          </Route>
          <Route path="/services" element={<Services />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/actualites" element={<Actualites />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/conditions" element={<ConditionsGenerales />} />
          <Route
            path="/politiqueconfidentialite"
            element={<PolitiqueConfidentialite />}
          />
          <Route path="/politiquecookies" element={<PolitiqueCookies />} />
        </Routes>
      </header>
      <SteepReservation />
      <FaqsList />
      <DeviseList />
      <Footer/>
      <Copyright />
<CookieConsent
  location="bottom"
  buttonText="J'accepte"
  declineButtonText="Je refuse"
  enableDeclineButton  // Activation du bouton "Je refuse"    
  cookieName="userConsent"
  style={{ 
    background: "white",  // Arrière-plan blanc
    color: "#000",  // Texte noir
    minHeight: "150px",  // Hauteur augmentée
    padding: "20px",  // Padding pour plus d'espace
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left"
  }}
  buttonStyle={{ 
    backgroundColor: "#FFD700",  // Jaune pour le bouton "J'accepte"
    color: "#000",  // Texte noir sur le bouton
    fontSize: "14px",
    borderRadius: "5px",
    padding: "10px 20px"  // Padding pour une meilleure visibilité
  }}
  declineButtonStyle={{ 
    background: "#FFD700",  // Jaune pour le bouton "Je refuse"
    color: "#000",  // Texte noir
    fontSize: "14px",
    borderRadius: "5px",
    padding: "10px 20px"
  }}
  expires={365}  // Durée de conservation des cookies
>
  Ce site utilise des cookies afin de vous offrir la meilleure expérience possible. Nous utilisons des cookies pour analyser le trafic, personnaliser le contenu, et vous proposer des services adaptés à vos centres d'intérêt. Vous pouvez accepter ou refuser ces cookies à tout moment.{" "}
  <a href="/politiquecookies" style={{ color: "#FFD700", textDecoration: "underline" }}>
    En savoir plus sur la gestion des cookies
  </a>.
</CookieConsent>


    </Box>
  );
}

export default App;
