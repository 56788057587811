import React from 'react';
import Copyright from '../Copyright';
import PartnerLinks from './PartnerLinks';
import { Box, Divider } from '@mui/material'; // N'oubliez pas d'importer Divider

const Footer = () => {
  return (
    <Box 
      component="footer"
      sx={{
        backgroundColor: 'black', // Couleur de fond noire
        color: 'white', // Texte en blanc
        padding: '20px 8px',
        textAlign: 'center',
        marginTop: 'auto',
      //  borderRadius: { xs: '0', sm: '160px 0 0 0' }, // Arrondi du footer
        boxShadow: 3, // Ajouter une ombre pour plus de profondeur
      }}
    >
      <PartnerLinks />
      
      {/* Ligne de séparation */}
      <Divider sx={{ backgroundColor: 'yellow', margin: '20px 20px' }} /> {/* Couleur et marges personnalisées */}
      
      <Copyright />
    </Box>
  );
};

export default Footer;
