import { createSlice } from "@reduxjs/toolkit";
export const reservationSlice=createSlice({
    name:"reservation",
    initialState:{
        value:{
            // reservation:{},
            steep:1,
            bureauRetrait:'',
            dateRetrait:'',
            heureRetrait:'',
            indicatif:'+212(MA)',
            devise:"",
            taux:"",
            typeOperation:""
        }
    },
    reducers:{
        addBureau:(state,action)=>{
            state.value={...state.value,
                bureauRetrait:action.payload.bureauRetrait,
                dateRetrait:action.payload.dateRetrait,
                heureRetrait:action.payload.heureRetrait,
                steep:action.payload.steep,
            }
        },
        // addOperation:(state,action)=>{
        //     // state.value={montantToExchane:actionn.payload.montant1,MontantToReceive:actionn.payload.montant2}
        //      state.value= {...state.value,
        //     montantToExchane:action.payload.montant1,
        //     MontantToReceive:action.payload.montant2,
        //     devise:action.payload.devise,
        //     taux:action.payload.taux,
        //     typeOperation:action.payload.typeOperation


        // }
        // },        

    }
});
 export const {convert,addBureau,action2}=reservationSlice.actions;
 export default reservationSlice.reducer;