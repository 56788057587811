import { Box, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "./aboutus.css";
import BureauList from "../reservation/BureauList";
import { Helmet } from 'react-helmet-async';

function aboutus() {
  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      pt: { xs: 4, sm: 8 },
      pb: { xs: 4, sm: 8 },
      background:"#ffee05",
    }}
    >
      <Helmet>
        <title>Qui sommes nous ? - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography variant='h3'
      align='center'
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color:'black',
        pb: { xs: 4, sm: 8 },
      }}
      >
        Vous servir dans les meilleurs conditions
        <Typography
          variant="h5"
          color="black"
          sx={{
            pb: { xs: 2, sm: 4 },
            fontSize: { xs: 16, sm: 32 },
          }}
        >
          C'est notre première préocupation
        </Typography>
      </Typography>
      <Grid
        container
         rowSpacing={1}
        columnSpacing={{ sm: 1, md: 2 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
      <Grid item xs={12}>
      <iframe
            style={{ width: "100%" }}
            src="https://www.google.com/maps/d/embed?mid=1FV8K69HUqrisaHF8siZTePbWKq8PpjA&ehbc=2E312F&noprof=1"
            // width="80vw"
            height="480"
          ></iframe>
      </Grid>
        {/* <Grid item xs={12} md={6}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // pt: { xs: 4, sm: 8 },
              // pb: { xs: 4, sm: 8 },
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" color="black">
              Mot du Directeur
            </Typography>

            <Typography paragraph>
              <Typography variant="h6" color="black">
                Chers clients,
              </Typography>
              Je suis ravi de vous accueillir sur le site web de notre société
              de change de devises. Chez nous, nous nous engageons à vous offrir
              un service de haute qualité et efficace pour toutes vos
              transactions de change. Que vous ayez besoin de devises étrangères
              pour un voyage d'affaires ou des vacances en famille, notre
              plateforme en ligne est là pour faciliter vos réservations en
              toute simplicité. Notre équipe dévouée est à votre disposition
              pour répondre à toutes vos questions et vous assister à chaque
              étape du processus de réservation. Nous nous efforçons de vous
              offrir les meilleurs taux de change du marché et une expérience
              utilisateur fluide et sécurisée. N'hésitez pas à parcourir notre
              site pour découvrir l'ensemble de nos services et options de
              réservation en ligne. Nous sommes impatients de vous accompagner
              dans chacune de vos transactions de change de devises. Bienvenue
              chez nous et merci de votre confiance. Cordialement, [Le nom du
              directeur]"
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} align={"center"}>
          <img
            src="images/b1.jpg"
            alt=""
            style={{ borderRadius: "8px", width: 480 }}
            className="animright"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: { xs: 2, sm: 4 },
              pb: { xs: 2, sm: 4 },
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" color="black">
              Nos Bureaux de change
            </Typography>
            <Typography paragraph>
              Découvrez notre sélection des 6 sociétés de change de devises les
              mieux situées à Marrakech, vous offrant une proximité inégalée
              pour vos besoins de change. Nos partenaires se distinguent par un
              service d'assistance et des informations approfondies pour
              répondre à toutes vos questions. De plus, accumulez des points de
              fidélité à chaque réservation en ligne, vous offrant des avantages
              exclusifs lors de vos prochaines transactions. Faites confiance à
              nos partenaires pour une expérience de change de devises pratique,
              personnalisée et avantageuse à Marrakech.
            </Typography>
          </Stack>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Typography paragraph>
            Un taux de change fait référence à la valeur d’une devise par
            rapport à une autre – par exemple, combien de pesos mexicains sont
            égaux à un dollar américain.
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} md={12}>
          <iframe
            style={{ width: "100%" }}
            src="https://www.google.com/maps/d/embed?mid=1FV8K69HUqrisaHF8siZTePbWKq8PpjA&ehbc=2E312F&noprof=1"
            // width="80vw"
            height="480"
          ></iframe>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <BureauList />
        </Grid>
      </Grid>
    </Container>
  );
}

export default aboutus;
