import React from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useGetNewsByIdQuery } from "../../app/services/newsApi";
import { useParams } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

function News() {
  const { id } = useParams();
  const { data: news, isLoading, isError } = useGetNewsByIdQuery(id);

  if (isLoading) return <CircularProgress />;

  if (isError)
    return (
      <Typography variant="h6">
        Erreur lors du chargement de l'actualité.
      </Typography>
    );

  // Create the share URL
  const shareUrl = window.location.href;

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        background: "white",
        marginBottom: { xs: 4, sm: 8 },
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 56 },
          color: "black",
          pb: { xs: 2, sm: 4 },
        }}
      >
        Actualités
      </Typography>
      <Typography
        variant="h4"
        align="left"
        sx={{
          fontSize: { xs: 20, sm: 32 },
          color: "black",
          pb: { xs: 2, sm: 4 },
          pl: 0,
        }}
      >
        {news.title}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Paper elevation={0} sx={{ textAlign: "center" }}>
            <img
              src={`${process.env.REACT_APP_BASE_FILE_URL}/news/${news.photo}`}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            sx={{
              textAlign: "left",
              px: 2,
            }}
          >
            <Typography variant="body1">
              <strong>{news.description}</strong>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="body1" gutterBottom sx={{ pt: 4, pb: 4 }}>
        {news.texte}
      </Typography>

      {/* Share Buttons */}
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <Tooltip title="Partager sur WhatsApp">
            <IconButton
              component="a"
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Partager sur Facebook">
            <IconButton
              component="a"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Partager sur Instagram">
            <IconButton
              component="a"
              href={`https://www.instagram.com/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Partager par email">
            <IconButton
              component="a"
              href={`mailto:?subject=Check out this news&body=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
}

export default News;
