import React, { useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useGetAllReservationsQuery } from "../../../app/services/reservationApi";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/Loader";

function GestionReservation() {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [nomFamille, setNomFamille] = useState("");

  const {
    data: reservations,
    isLoading,
    isError,
  } = useGetAllReservationsQuery();

  if (isLoading) {
    return <Loader />;
  }

  const filteredReservations =
    reservations &&
    reservations.filter(
      (reservation) =>
        reservation.nomFamille
          .toLowerCase()
          .includes(nomFamille.toLowerCase()) &&
        reservation.id.toString().includes(code)
    );

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "yellow",
        padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des réservations</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les réservations effectuées par les clients.
      </Typography>

      <Stack
        direction={"row"}
        spacing={2}
        align="left"
        sx={{
          backgroundColor: "white",
          marginBottom: 2,
          borderRadius: 2,
          padding: 2,
          display: "flex",
        }}
      >
        <Stack direction={"column"} spacing={2} align="left">
          <Typography variant="h5">Filtre</Typography>
          <Typography variant="h6">
            Filtrer les réservations selon le code ou le nom
          </Typography>
        </Stack>

        <Stack direction={"column"} spacing={2} align="left">
          <InputLabel id="code">Code réservation</InputLabel>
          <TextField
            id="code"
            name="code"
            size="small"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </Stack>
        <Stack direction={"column"} spacing={2} align="left">
          <InputLabel id="nomFamille">Nom de famille</InputLabel>
          <TextField
            id="nomFamille"
            name="nomFamille"
            size="small"
            value={nomFamille}
            onChange={(event) => setNomFamille(event.target.value)}
          />
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Code réservation</TableCell>
              <TableCell>Nom Famille</TableCell>
              <TableCell>Deuxième Nom Famille</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Détail de la réservation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReservations &&
              filteredReservations.map((reservation) => (
                <TableRow
                  key={reservation.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{reservation.id}</TableCell>
                  <TableCell align="left">{reservation.nomFamille}</TableCell>
                  <TableCell align="left">{reservation.nomFamille2}</TableCell>
                  <TableCell align="left">{reservation.prenom}</TableCell>
                  <TableCell align="left">
                    <Button
                      size="small"
                      onClick={() => {
                        navigate(`/admin/detailsreservation/${reservation.id}`);
                      }}
                    >
                      <ManageAccountsIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default GestionReservation;
