import * as React from "react";
import { useGetNewsQuery } from "../../app/services/newsApi"; // Assurez-vous que le chemin correspond à votre fichier newsApi.js
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useNavigate } from "react-router-dom"; // Import de useNavigate

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SwipeableTextMobileStepper = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate(); // Récupérer la fonction navigate

  // Utiliser RTK Query pour récupérer les actualités
  const { data: news, isLoading, isError } = useGetNewsQuery();
  const maxSteps = news ? news.length : 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  if (isLoading) return <loader />;
  if (isError) return <div>Error fetching news</div>;

  return (
    <Box
      sx={{
        maxWidth: 480,
        flexGrow: 1,
      }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 88,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography paragraph>{news[activeStep].title}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {news.map((step, index) => (
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                src={`${process.env.REACT_APP_BASE_FILE_URL}/news/${step.photo}`}
                //src={`http://localhost:8000/storage/news/${step.photo}`}
                alt={step.title}
                onClick={() => navigate(`/news/${step.id}`)} // Utiliser navigate pour naviguer vers l'URL de l'image
                style={{
                  height: 320,
                  display: "block",
                  maxWidth: 480,
                  overflow: "hidden",
                  width: "100%",
                  cursor: "pointer", // Optionnel : changer le curseur au survol
                }}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};
export default SwipeableTextMobileStepper;
